import React from 'react'

export default function Header(){
    return(
        <>
            <section className="hero-wrap" style={{backgroundImage: `url('assets/images/fondo-logo-cavi.jpg')`}} data-stellar-background-ratio="0.5">
                {/*<div className="overlay"></div>*/}
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center"> 
                        <div className="col-lg-12 col-md-6" style={{textAlign:'center',marginTop:'-150px'}}>
                                <img 
                                    alt="..." 
                                    src={process.env.PUBLIC_URL+"/assets/images/logo-cavi.png"}
                                    width="75%">
                                </img>
                        </div>
                        <div className="col-lg-12"style={{marginTop:'-430px'}}>
                            <div className="text">
                                <a href='https://concavi.com.mx' target={"blank"}>
                                    <img 
                                        alt="..." 
                                        src={process.env.PUBLIC_URL+"/assets/images/min-concavi.png"}
                                        width="15%"
                                        height="15%"
                                        style={{marginRight:'5%'}}>
                                    </img>
                                </a>
                                <a href='https://construcavi.com.mx' target={"blank"}>
                                    <img 
                                        alt="..." 
                                        src={process.env.PUBLIC_URL+"/assets/images/min-construcavi.png"}
                                        width="15%"
                                        height="15%"
                                        style={{marginRight:'5%'}}>
                                    </img>
                                </a>
                                <a href='https://grupocavi.com.mx/cavi' target={"blank"}>
                                    <img 
                                        alt="..." 
                                        src={process.env.PUBLIC_URL+"/assets/images/min-cavi.png"}
                                        width="15%"
                                        height="15%"
                                        style={{marginRight:'5%'}}>
                                    </img>
                                </a>
                                <a href='https://maquicavi.com.mx' target={"blank"} className='min-icons'>
                                    <img 
                                        alt="..." 
                                        src={process.env.PUBLIC_URL+"/assets/images/min-maquicavi.png"}
                                        width="15%"
                                        height="15%"
                                        style={{marginRight:'5%'}}>
                                    </img>
                                </a>
                                <a href='https://cavimac.com.mx' target={"blank"} className='min-icons'>
                                    <img 
                                        alt="..." 
                                        src={process.env.PUBLIC_URL+"/assets/images/min-cavimac.png"}
                                        width="15%"
                                        height="15%">
                                    </img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}