import React from 'react'

import Navbar from "../components/Navbar.js";
import SustainabilityCatalog from "../components/SustainabilityCatalog.js";
import Footer from "../components/Footer.js";

export default function Sustainability(){
    return(
        <>
        <Navbar />
        <section className="hero-wrap" style={{backgroundImage: `url('assets/images/fondo_sostenibilidad.jpg')`}} data-stellar-background-ratio="0.5">
            <div className="overlay"></div>
            <div className="container">
                <div className="row no-gutters slider-text align-items-center justify-content-center">
                    <div className="col-md-9 pb-0 text-center">
                    <h3 className="mb-3 bread">
                        <a style={{color:'#fff'}} href="/sostenibilidad">Sostenibilidad <i className="fa fa-chevron-right"></i></a>
                        <a href="/videos">Videos <i className="fa fa-chevron-right"></i></a>
                        <a href="/covid">COVID-19 <i className="fa fa-chevron-right"></i></a>
                      </h3>
                    </div>
                </div>
            </div>
        </section>
        <SustainabilityCatalog />
        <Footer />
        </>
    );
}