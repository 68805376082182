import React from 'react'
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

function createServiceCarousel(carouselImages) {
    return (
        <Carousel.Item interval={2000} style={{width: '800px'}}>
            <img 
                src={process.env.PUBLIC_URL+carouselImages.slImage}
                alt="..." 
                key={carouselImages.id}
                height={400}
            />
        </Carousel.Item>
    );
  }

export default function ServicesSlider(props){

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    let gallery = props.slImages;
    return(
        <>
            <section className="ftco-section ftco-no-pt" id="proyectos">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center heading-section mb-5">
                            <h2 className="mb-3">{props.slSubtitle}</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <Carousel controls={false} indicators={false} activeIndex={index} onSelect={handleSelect}>
                                {gallery.map(createServiceCarousel)}    
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}