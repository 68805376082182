import React from 'react'
import ClientMin
 from './ClientMin';
export default function Clients(){
    return(
        <>
            <section class="ftco-section">
                <div class="container">
                    <div class="row justify-content-center mb-5">
                        <div class="col-md-7 heading-section text-center">
                            <span class="subheading">Calidad</span>
                            <h2>Certificaciones</h2>
                        </div>
                    </div>
                    <div class="row d-flex">
                        <ClientMin cmImage={process.env.PUBLIC_URL+"/assets/images/logo_covid.png"} cmWith={320} cmHeight={200}/>
                        <ClientMin cmImage={process.env.PUBLIC_URL+"/assets/images/100.jpg"} cmWith={320} cmHeight={200}/>
                        <ClientMin cmImage={process.env.PUBLIC_URL+"/assets/images/passt_logo.jpg"} cmWith={180} cmHeight={200}/>
                    </div>
                </div>
            </section>
        </>
    );
}