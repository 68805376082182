import React from 'react'

import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import CovidFeature from '../components/CovidFeature.js';
import ProtocolCovid from '../components/ProtocolCovid.js';
import ProtocolsList from '.././protocols-list.js';

export default function Covid19(){
    function createPContainer(prot) {
        return (
            <ProtocolCovid 
                key={prot.id}
                pImage={process.env.PUBLIC_URL+prot.protocolImage}
                pLink={prot.protocolLink}
                pName={prot.protocolSubheading}
                pYear={prot.protocolYear}
            />
        );
    }
    return(
        <>
        <Navbar />
        <section className="hero-wrap" style={{backgroundImage:`url('assets/images/fondo_covid.jpg')`}} data-stellar-background-ratio="0.5">
            <div className="overlay"></div>
            <div className="container">
                <div className="row no-gutters slider-text align-items-center justify-content-center">
                    <div className="col-md-9 pb-0 text-center">
                        <h3 className="mb-3 bread">
                            <a href="/sostenibilidad">Sostenibilidad <i className="fa fa-chevron-right"></i></a>
                            <a href="/videos">Videos <i className="fa fa-chevron-right"></i></a>
                            <a style={{color:'#fff'}} href="/covid">COVID-19 <i className="fa fa-chevron-right"></i></a>
                        </h3>
                    </div>
                </div>
            </div>
        </section>
        <section className="ftco-section" style={{backgroundColor:'#AF252E', padding: '2em'}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-11 text-center heading-section heading-section-white">
                        <p style={{fontStyle: 'italic'}}>Remarcamos nuestro compromiso de proteger la integridad de todos nuestros trabajadores, clientes y visitantes, promoviendo la seguridad y la salud mediante protocolos de acción en apego a los lineamientos de la OMS (Organización Mundial de la Salud) a fin de frenar el riesgo de contagio.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="ftco-section ftco-property-details">
	        <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="property-details">
                            <div className="img" style={{backgroundImage: `url('assets/images/miniaturas/miniatura_video.jpg')`, height: '600px'}}>
                                <a href="https://www.youtube-nocookie.com/embed/whsd4kOGEaM?rel=0" className="img-video popup-vimeo d-flex align-items-center justify-content-center">
                                <span className="fa fa-play"></span>
                            </a>
                            </div>
                            <div className="text">
                                <span className="subheading">COVID-19</span>
                                <h2>Medidas de Salud y Seguridad</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pills">
                        <div className="bd-example bd-example-tabs">
                            <div className="d-flex">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-manufacturer-tab" data-toggle="pill" href="#pills-manufacturer" role="tab" aria-controls="pills-manufacturer" aria-expanded="true">El Comité</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-description-tab" data-toggle="pill" href="#pills-description" role="tab" aria-controls="pills-description" aria-expanded="true">Actividades</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade" id="pills-description" role="tabpanel" aria-labelledby="pills-description-tab">
                                    <div className="row">
                                        <CovidFeature 
                                            Name1="Capacitaciones"
                                            Name2="Cursos del IMSS"
                                            Name3="Campaña de Salud Mental"
                                            Name4="Campaña Anti-Estrés"
                                            Name5="Tips Psicológicos"
                                        />
                                        <CovidFeature 
                                            Name1="Campaña de Inteligencia Emocional"
                                            Name2="Uso correcto de EPP"
                                            Name3="Filtros de salud"
                                            Name4="Desinfección y Sanitización de Áreas"
                                            Name5="Sana Distancia"
                                        />
                                        <CovidFeature 
                                            Name1="Medidas de Higiene"
                                            Name2="Cerco Sanitario"
                                            Name3="Check-list de Salud"
                                            Name4="Señalización"
                                            Name5="Campaña de Reforzamiento"
                                        />
                                    </div>
                                </div>
                                <div class="tab-pane fade show active" id="pills-manufacturer" role="tabpanel" aria-labelledby="pills-manufacturer-tab">
                                    <p>Comité de Seguridad y Salud Sanitaria ante el COVID-19<br></br></p>
                                    <p>Objetivo General</p><p>Establecer las acciones a seguir en conformidad con el acuerdo por el que se establecen los lineamientos técnicos específicos para la reapertura de las actividades económicas publicado en el Diario Oficial de la Federación el Viernes 29 de mayo de 2020.</p>
                                    <p>Funciones y Responsabilidades</p>
                                    <ol>
                                        <li>Acciones de vigilancia y supervisión</li>
                                        <ul>
                                            <li>Verificar el establecimiento de las medidas de prevención y protección en el centro de trabajo.</li>
                                            <li>Verificar la provisión constante de agua, jabón y toallas desechables, y de soluciones a base de alcohol gel al 60% - 70% en todas las áreas de las empresas y centros de trabajo.</li>
                                            <li>Monitorear las disposiciones que establezcan las autoridades competentes para las posibles modificaciones de las acciones a seguir en el centro de trabajo.</li>
                                            <li>Llevar el registro y seguimiento del personal en resguardo domiciliario voluntario y evaluar posibles casos de contagio.</li>
                                            <li>Establecer un mecanismo de seguimiento de personas trabajadoras en aislamiento y, en caso de ser necesario, contactar a la autoridad sanitaria estatal en los números disponibles en https://coronavirus.gob.mx/contacto/.</li>
                                        </ul>
                                        <li>Medidas de protección para la población vulnerable en centros de trabajo ubicados en regiones designadas de alerta alta e intermedia</li>
                                        <ul>
                                            <li>Priorizar el trabajo a distancia con el fin de evitar la asistencia al centro de trabajo y reducir el riesgo de contagio del personal en condición de vulnerabilidad.</li>
                                            <li>En caso de no ser posible hacer trabajo a distancia, el comité deberá garantizar las siguientes medidas especiales:</li>
                                            <ul>
                                                <li>Identificar para cada departamento o área del centro de trabajo al personal en situación de vulnerabilidad.</li>
                                                <li>Cerciorarse de que dicha población cuente con equipo de protección personal desechable y con soluciones a base de alcohol gel al 60% - 70% a disposición permanente.</li>
                                                <li>Verificar el establecimiento de medidas que resulten en una reducción de densidad humana en los espacios de trabajo, garantizando una distancia mínima efectiva entre las estaciones de trabajo de 1.5 metros.</li>
                                                <li>Permitir el ingreso en horarios diferentes al resto del personal, para evitar los horarios pico en el transporte público o en el transporte de personal.</li>
                                                <li>Establecer zonas exclusivas en área de alimentos, comedores y/o vestidores, para reducir el riesgo de exposición del personal en mayor riesgo.<br></br>En caso de no ser posible establecer zonas exclusivas, se deberán establecer horarios diferenciados para reducir el riesgo de contagio del personal identificado como vulnerable.</li>
                                                <li>Considerar todos los procesos en los que participa personal en situación de vulnerabilidad que pudieran verse afectados, por lo que deberá garantizarse que la implementación de estas medidas no se utilice para discriminar o limitar su derecho al trabajo.</li>
                                            </ul>
                                        </ul>
                                        <li>Medidas de seguridad sanitaria en el entorno laboral</li>
                                        <ul>
                                            <li>Apoyar en la implementación de las medidas de seguridad sanitaria en el entorno laboral que se presentan en el Acuerdo para la continuidad de operación o regreso a laborar, el correcto cumplimiento de las medidas de seguridad será responsabilidad del centro de trabajo.</li>
                                        </ul>
                                    </ol>
                                    <p>Integrantes</p><p>El comité lo conforma personal de gerencia, superintendencia y supervisión de seguridad e higiene, personal encargado del SASST, así como personal de gerencia de capital humano y personal del laboratorio químico.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="ftco-section ftco-no-pt">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center heading-section mb-5">
                        <span className="subheading">PROTOCOLOS</span>
                        <h2 className="mb-3">COVID-19</h2>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {ProtocolsList.map(createPContainer)}
                </div>
            </div>
        </section>
        <Footer />
        </>
    )
}