import React from 'react'

export default function Directors(){
    return(
        <>
            <section className="ftco-section ftco-no-pb ftco-no-pt">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 wrap-about py-md-5 text-left"></div>
                        <div className="col-md-6 wrap-about py-md-5 text-center">
                            <div className="heading-section pr-md-5">
                                <h2 className="mb-4">GRUPO CAVI</h2>
                                <h5>VISIÓN</h5>
                                <p align="justify">Ser la empresa Zacatecana líder a nivel Nacional dentro del sector competitivo,
                                    reconocida por su Calidad, Seguridad y Cumplimiento de los compromisos adquiridos.
                                </p>
                                <h5>MISIÓN</h5>
                                <p align="justify">Garantizar a través de nuestros servicios, procesos de efectividad que generen
                                    resultados extraordinarios.
                                </p><br></br>
                                <h5>VALORES</h5>
                                <h6 className="text-left"><strong><h4>C</h4>Compromiso</strong></h6>
                                <p align="justify">Somos conscientes de la importancia que existe en cumplir con algo acordado,
                                    asumimos la responsabilidad de cumplir con nuestros compromisos y objetivos.
                                </p>
                                <h6 className="text-left"><strong><h4>A</h4>Aprendizaje</strong></h6>
                                <p align="justify">Sabemos que nadie tiene todas las respuestas. Una cultura de humildad y
                                    conocimientos nuevos continuos es un principio fundamental, con el aprendizaje se adquieren
                                    habilidades, destrezas, conocimientos, conductas y valores que ayudan a cumplir con los
                                    resultados extraordinarios.
                                </p>
                                <h6 className="text-left"><strong><h4>V</h4>Voluntad</strong></h6>
                                <p align="justify">Reconocemos que cualquier meta se puede lograr teniendo fuerza de voluntad,
                                    trabajamos con constancia y disciplina para cumplir con los objetivos de Calidad.
                                </p>
                                <h6 className="text-left"><strong><h4>I</h4>Integridad</strong></h6>
                                <p align="justify">Actuamos con honestidad y honor sin comprometer la verdad pase lo que pase,
                                    cuidamos todo lo que la empresa nos encomienda relativo al manejo de la información confidencial,
                                    salvaguarda de los activos, así como de su patrimonio.
                                </p>
                                <h5>CULTURA EMPRESARIAL</h5>
                                <p align="justify">Grupo CAVI es un lugar donde es posible crecer, aprender y mejorar de forma continua, es un lugar que se enfoca
                                    en cuidar el bienestar personal y profesional.
                                </p>
                                <img style={{"max-width":"25vw", "padding-bottom": "15px"}} src="assets/images/circles.png" alt="logo"></img>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}