import React from "react";

import Navbar from "../components/Navbar.js";
import Header from "../components/Header.js";

export default function Index() {
  return (
    <>
      <Navbar/>
      <Header />
    </>
  );
}