import React from "react";

export default function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={process.env.PUBLIC_URL + "/assets/images/logo_cavi.png"} style={{width: 180, height: 120}} alt="..."></img>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="oi oi-menu"></span> Menu
          </button>
          <div className="collapse navbar-collapse" id="ftco-nav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item"><a href="/" className="nav-link">Inicio</a></li>
              <li className="nav-item"><a href="/acerca" className="nav-link">Acerca de</a></li>
              <li className="nav-item"><a href="/cavi" className="nav-link">Servicios</a></li>
              <li className="nav-item"><a href="/sostenibilidad" className="nav-link">Sostenibilidad</a></li>
              <li className="nav-item"><a href="/contacto" className="nav-link">Contacto</a></li>
            </ul>
          </div>
        </div>
      </nav>
      {/*<button type="button" className="btn" data-toggle="modal" style={{width:'20%', marginTop:25, position:'fixed', right:'6%', zIndex:800, backgroundColor:'white', color:'#AF252E'}} data-target="#ptuInfo">
        <strong>REPARTO DE UTILIDADES 2024</strong>
      </button>
      <div className="modal fade" id="ptuInfo" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">Reparto de Utilidades 2024</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img style={{maxWidth:'100%',maxHeight:'100%'}} alt="..." src={process.env.PUBLIC_URL +"assets/images/ptu_montajes.jpg"}></img>
            <img style={{maxWidth:'100%',maxHeight:'100%'}} alt="..." src={process.env.PUBLIC_URL +"assets/images/ptu_concretos.jpg"}></img>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          </div>
          </div>
        </div>*
      </div>*/}
    </>
  );
}