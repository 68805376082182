import React from 'react'

import Navbar from "../components/Navbar.js";
import Heading from "../components/Heading.js";
import Footer from "../components/Footer.js";

export default function Contact(){

    return(
        <>
            <Navbar />
            <Heading hImage={`url('assets/images/fondo_contacto.jpg')`} hTitle={'Contacto'}/>
            <section className="ftco-section contact-section">
                <div className="container">
                    <div className="row d-flex mb-5 contact-info justify-content-center">
                        <div className="col-md-8">
                            <div className="row mb-5">
                                <div className="col-md-6 text-center py-4">
                                    <div className="icon">
                                        <span className="fa fa-map"></span>
                                    </div>
                                    <p><span>Dirección:</span> Benemérito de las Américas 137, Col. López Mateos, 99102 Sombrerete, ZAC.</p>
                                </div>
                                <div className="col-md-6 text-center border-height py-4">
                                    <div className="icon">
                                        <span className="fa fa-phone"></span>
                                    </div>
                                    <p><span>Teléfonos:</span> <a href="tel://4339352833">(433) 935 2833 | (433) 935 2232</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="col-lg-12"style={{}}>
                                <div className="text">
                                    <a href='https://concavi.com.mx' target={"blank"}>
                                        <img 
                                            alt="..." 
                                            src={process.env.PUBLIC_URL+"/assets/images/min-concavi.png"}
                                            width="15%"
                                            height="15%"
                                            style={{marginRight:'5%'}}>
                                        </img>
                                    </a>
                                    <a href='https://construcavi.com.mx' target={"blank"}>
                                        <img 
                                            alt="..." 
                                            src={process.env.PUBLIC_URL+"/assets/images/min-construcavi.png"}
                                            width="15%"
                                            height="15%"
                                            style={{marginRight:'5%'}}>
                                        </img>
                                    </a>
                                    <a href='https://grupocavi.com.mx/cavi' target={"blank"}>
                                        <img 
                                            alt="..." 
                                            src={process.env.PUBLIC_URL+"/assets/images/min-cavi.png"}
                                            width="15%"
                                            height="15%"
                                            style={{marginRight:'5%'}}>
                                        </img>
                                    </a>
                                    <a href='https://maquicavi.com.mx' target={"blank"} className='min-icons'>
                                        <img 
                                            alt="..." 
                                            src={process.env.PUBLIC_URL+"/assets/images/min-maquicavi.png"}
                                            width="15%"
                                            height="15%"
                                            style={{marginRight:'5%'}}>
                                        </img>
                                    </a>
                                    <a href='https://cavimac.com.mx' target={"blank"} className='min-icons'>
                                        <img 
                                            alt="..." 
                                            src={process.env.PUBLIC_URL+"/assets/images/min-cavimac.png"}
                                            width="15%"
                                            height="15%">
                                        </img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>	
            <Footer />
        </>
    )
}