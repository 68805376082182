/*eslint-disable*/
import React from "react";

export default function Footer() {
  return (
    <footer className="ftco-footer ftco-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">GRUPO CAVI</h2>
              <p>Sigue nuestras redes.</p>
              <ul className="ftco-footer-social list-unstyled mt-5">
              <li className="ftco-animate"><a href="https://www.youtube.com/channel/UCumqRBmJSwOMj1pfnQZQh4w"><span className="fa fa-youtube"></span></a></li>
              <li className="ftco-animate"><a href="https://www.facebook.com/cavidesombrerete/"><span className="fa fa-facebook"></span></a></li>
              <li className="ftco-animate"><a href="https://www.instagram.com/cavisombrerete/"><span className="fa fa-instagram"></span></a></li>
              </ul>
            </div>
          </div>
          <div className="col-md">
          <div className="ftco-footer-widget mb-4 ml-md-4">
            <h2 className="ftco-heading-2">Navegación</h2>
            <ul className="list-unstyled">
              <li><a href="/"><span className="fa fa-chevron-right mr-2"></span>Inicio</a></li>
              <li><a href="/acerca"><span className="fa fa-chevron-right mr-2"></span>Acerca de</a></li>
              <li><a href="/servicios"><span className="fa fa-chevron-right mr-2"></span>Servicios</a></li>
            </ul>
          </div>
          </div>
          <div className="col-md">
          <div className="ftco-footer-widget mb-4 ml-md-4">
            <h2 className="ftco-heading-2" style={{color:'#0f101c'}}>-</h2>
            <ul className="list-unstyled">
            <li><a href="/proyectos"><span className="fa fa-chevron-right mr-2"></span>Proyectos</a></li>
            <li><a href="/videos"><span className="fa fa-chevron-right mr-2"></span>Videos</a></li>
            <li><a href="/sostenibilidad"><span className="fa fa-chevron-right mr-2"></span>Sostenibilidad</a></li>
            </ul>
          </div>
          </div>
          <div className="col-md">
          <div className="ftco-footer-widget mb-4 ml-md-4">
            <h2 className="ftco-heading-2" style={{color:'#0f101c'}}>-</h2>
            <ul className="list-unstyled">
            <li><a href="/covid"><span className="fa fa-chevron-right mr-2"></span>COVID-19</a></li>
            <li><a href="/contacto"><span className="fa fa-chevron-right mr-2"></span>Contacto</a></li>
            </ul>
          </div>
          </div>
          <div className="col-md">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2">Contacto</h2>
            <div className="block-23 mb-3">
              <ul>
                <li><span className="icon fa fa-map"></span><span className="text">Calle Benemérito de las Américas 137<br></br>Col. López Mateos 99102, Sombrerete, Zacatecas, MEX</span></li>
                <li><a href="#"><span className="icon fa fa-phone"></span><span className="text">433 935 28 33 <br></br>433 935 22 32</span></a></li>
              </ul>
            </div>
          </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <p>
              &copy;{new Date().getFullYear()} Made with <i className="fa fa-heart" aria-hidden="true"></i> by GSIM | <a href="https://grupocavi.com.mx" target="_blank">GRUPOCAVI</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}