import React from "react";

import ServicesCatalog from "../components/ServicesCatalog.js";
import ServicesSlider from "../components/ServicesSlider.js";
import caviSlider from '../slider-cavi-list.js';

export default function Services() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={process.env.PUBLIC_URL + "/assets/images/logo_cavi.png"} style={{width: 180, height: 120}} alt="..."></img>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="oi oi-menu"></span> Menu
          </button>
          <div className="collapse navbar-collapse" id="ftco-nav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-itemCavi" style={{fontColor:'white'}}><a href="#servicios" className="nav-link">Servicios</a></li>
              {/*<li className="nav-itemCavi"><a href="#marketing" className="nav-link">Marketing</a></li>*/}
              <li className="nav-itemCavi"><a href="#proyectos" className="nav-link">Proyectos</a></li>
              <li className="nav-itemCavi"><a href="#contacto" className="nav-link">Contacto</a></li>
            </ul>
          </div>
        </div>
      </nav>
      <section className="hero-wrap" style={{backgroundImage: `url('assets/images/fondo_servicios.jpg')`}} data-stellar-background-ratio="0.5">
          <div className="overlay"></div>
          <div className="container">
              <div className="row no-gutters slider-text align-items-center justify-content-center">
                  <div className="col-md-4 pb-0 text-center">
                    <img style={{"max-width":"25vw", "padding-bottom": "15px"}} src="assets/images/min-cavi.png" alt="logo"></img>
                  </div>
                  <div className="col-md-8 pb-0 text-center">
                      <h1 className="mb-3 bread" style={{fontSize:'2rem'}}>MANTENIMIENTO ESPECIALIZADO EN TODO TIPO DE PLANTAS Y MAQUINARIA EN GENERAL</h1>
                  </div>
              </div>
          </div>
      </section>
      <ServicesCatalog/>
      <ServicesSlider slSubtitle={"Mantenimiento en Plantas y Maquinaria"} slLogo={'assets/images/min-cavi.png'} slImages={caviSlider}/>
      <section className="ftco-section contact-section" id="contacto">
                <div className="container">
                    <div className="row d-flex mb-5 contact-info justify-content-center">
                        <div className="col-md-8">
                            <div className="row mb-5">
                                <div className="col-md-12 text-center py-4">
                                    <div className="icon">
                                        <span className="fa fa-airplane"></span>
                                    </div>
                                    <p><span>CONTÁCTANOS HOY</span></p>
                                    <p>moises_fernandez@grupocavi.com.mx</p>
                                    <p>493 114 1675</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  );
}